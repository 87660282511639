import { listNotes } from '~/pages/SystemNotes/api/systemNotes.api';
import { NotesListModel } from '~/pages/SystemNotes/models/NotesListModel';
import { makeQuery } from '~/shared/api/internal/utils';
import { SystemModel } from '~/shared/models/system/SystemModel';

export const listNotesQuery = (system: SystemModel) =>
  makeQuery({
    queryKey: ['systemNotes', system.name],
    queryFn: async () => {
      const availableResources = NotesListModel.getSystemResources(system);

      const promises = availableResources.map((resource) =>
        listNotes(resource.resourceName),
      );
      const response = await Promise.all(promises);
      return new NotesListModel(response, system);
    },
  });
