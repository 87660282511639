import { Timestamp } from '@bufbuild/protobuf';
import { ListEventsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { EventType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import { NoteType, noteTypes } from '~/pages/SystemNotes/models/NoteModel';
import { aqoraClient } from '~/services/api/aqora-client';

export const listNotes = (resourceName: string): Promise<ListEventsResponse> =>
  aqoraClient.listEvents({
    resourceName,
    pageSize: BigInt(999999),
    filterTypes: [...noteTypes],
  });

export const createNote = ({
  resourceName,
  type,
  content,
}: {
  resourceName: string;
  type: NoteType;
  content: string;
}) =>
  aqoraClient.createNote({
    resourceName,
    eventType: +type,
    content,
  });

export const deleteNote = ({
  resourceName,
  type,
  timestamp,
}: {
  resourceName: string;
  type:
    | EventType.NOTE_ACTION
    | EventType.NOTE_OBSERVATION
    | EventType.NOTE_PROBLEM;
  timestamp?: Timestamp;
}) =>
  aqoraClient.deleteNote({
    resourceName,
    eventType: type,
    time: timestamp,
  });
