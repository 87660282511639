import { ListMetricsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  Metric_DataPoint,
  MetricType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import { roundNumericMetric } from '~/shared/models/metrics/metric-conversions';
import { assertUnreachable } from '~/types/assert-type';

// TODO: Move these to a new file - shared/models/Metric.ts

export function getSensorValue({ value }: Metric_DataPoint): number {
  switch (value.case) {
    case 'asDouble':
      return value.value;
    case 'asInt':
      return Number(value.value);
    case undefined:
      throw new Error('Found undefined sensor value');
    default:
      return assertUnreachable(value);
  }
}

export function getElapsedTimeSince(timestamp: number | Date): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} {
  const currentDate = dayjs();
  const targetDate = dayjs(timestamp);
  const duration = dayjs.duration(currentDate.diff(targetDate));

  return {
    days: Math.floor(duration.asDays()),
    hours: Math.floor(duration.asHours()),
    minutes: Math.floor(duration.asMinutes()),
    seconds: Math.floor(duration.asSeconds()),
  };
}

export function isMetricReadingOutdated(timestamp: number | Date): boolean {
  const now = dayjs();
  const targetTime = dayjs(timestamp);

  const diff = now.diff(targetTime, 'hour');

  return diff >= 1;
}

export function getOutdatedReadingLabel(
  t: TFunction,
  timestamp: number | Date,
): string {
  const { hours, days } = getElapsedTimeSince(timestamp);

  if (hours < 24) {
    return t('relativeDate.hours', {
      count: hours,
      defaultValue: '',
    });
  }
  return t('relativeDate.days', {
    count: days,
    defaultValue: '',
  });
}

export function extractMetricValues(
  listMetricsResponse: ListMetricsResponse,
): Partial<Record<MetricType, number>> {
  if (listMetricsResponse.metrics.length === 0) {
    throw new Error('No metrics found');
  }

  const metricData: Partial<Record<MetricType, number>> = {};

  listMetricsResponse.metrics.forEach((metric) => {
    const sensorValue = getSensorValue(metric.dataPoints[0]);
    metricData[metric.type] = roundNumericMetric(metric.type, sensorValue);
  });
  return metricData;
}
