import { Timestamp } from '@bufbuild/protobuf';
import {
  ListMetricsRequest_AggregationType,
  ListMetricsRequest_SortOrder,
  ListMetricsResponse,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import {
  Device,
  Reservoir,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { Dayjs } from 'dayjs';
import { aqoraClient } from '~/services/api/aqora-client';
import { authService } from '~/services/auth/auth-service';
import { TRANSPORT_URL } from '~/services/environment/environment';

const getLastFiveYearsRange = () => {
  const now = new Date();
  const pastFiveYears = new Date(now.setFullYear(now.getFullYear() - 5));

  return {
    rangeFrom: Timestamp.fromDate(pastFiveYears),
    rangeTo: Timestamp.fromDate(new Date()),
  };
};

const getLastWeekRange = () => {
  const now = new Date();
  const pastWeek = new Date(now.setDate(now.getDate() - 7));

  return {
    rangeFrom: Timestamp.fromDate(pastWeek),
    rangeTo: Timestamp.fromDate(new Date()),
  };
};

const getLast12Hours = () => {
  const now = new Date();
  const twelveHoursAgo = new Date(now.getTime() - 12 * 60 * 60 * 1000);

  return {
    rangeFrom: Timestamp.fromDate(twelveHoursAgo),
    rangeTo: Timestamp.fromDate(now),
  };
};

export const listMetrics = async (
  deviceName: Device['name'],
  metricTypes: Set<MetricType>,
  aggregation: ListMetricsRequest_AggregationType = ListMetricsRequest_AggregationType.UNSPECIFIED,
): Promise<ListMetricsResponse> => {
  const isMostRecentOnly =
    aggregation === ListMetricsRequest_AggregationType.MOST_RECENT_ONLY;
  const { rangeFrom, rangeTo } = isMostRecentOnly
    ? getLastWeekRange()
    : getLastFiveYearsRange();
  return await aqoraClient.listMetrics({
    resourceNames: [deviceName],
    types: Array.from(metricTypes),
    sortOrder: ListMetricsRequest_SortOrder.ASC,
    aggregation,
    rangeFrom,
    rangeTo,
  });
};

export const getWaterLevelMetrics = async (
  resourceNames: Reservoir['name'][],
  currentOnly?: boolean,
): Promise<ListMetricsResponse> =>
  await aqoraClient.listMetrics({
    resourceNames,
    types: [MetricType.WATER_HEIGHT_MM],
    aggregation: ListMetricsRequest_AggregationType.MOST_RECENT_ONLY,
    ...(currentOnly ? getLast12Hours() : getLastFiveYearsRange()),
  });

export type DownloadMetricsBody = {
  resource_names: Device['name'][];
  filename: string;
  from: string;
  to: string;
};

export const downloadMetricsFile = async ({
  deviceName,
  filename,
  from,
  to,
}: {
  deviceName: Device['name'];
  filename: string;
  from: Dayjs;
  to: Dayjs;
}): Promise<Blob> => {
  const token = await authService.getIdentityToken();

  const requestBody = {
    resource_names: [deviceName],
    filename,
    from: from.tz().format(),
    to: to.tz().format(),
  } satisfies DownloadMetricsBody;

  const response = await fetch(`${TRANSPORT_URL}/download/metrics`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok && response.status === 404) {
    throw new Error('No metrics found for the given time range');
  }

  if (!response.ok) {
    throw new Error('Error while fetching the metric file');
  }

  return await response.blob();
};
