import { ListIssuesResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import {
  Device,
  Device_CommissioningState,
  Drain,
  Peripheral,
  Product,
  SilentHours,
  System,
  SystemAutomation,
  Topology,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/system_pb';
import { idFromName } from '~/shared/models/id-utils';
import { IssueModel } from '~/shared/models/issues/IssueModel';
import { IssuesModel } from '~/shared/models/issues/IssuesModel';
import { DeviceModel } from '~/shared/models/system/DeviceModel';
import { DrainModel } from '~/shared/models/system/DrainModel';
import { ReservoirModel } from '~/shared/models/system/ReservoirModel';
import { ProductInfo, ProductModel } from '~/shared/models/system/ProductModel';
import {
  productionStateToSystemState,
  SystemState,
} from '~/shared/models/system/SystemState';
import {
  assertIsDefined,
  assertIsNonBlankString,
  assertUnreachable,
} from '~/types/assert-type';

export { ReservoirModel };

function getProductContext(
  products: Product[],
  firstProduct: Product,
  lastProduct: Product,
) {
  const firstProductIndex = products.indexOf(firstProduct);
  const lastProductIndex = products.indexOf(lastProduct);

  const previousProduct = products.at(firstProductIndex - 1);
  assertIsDefined(previousProduct);
  const nextProduct = products[(lastProductIndex + 1) % products.length];
  return {
    previousProductName: previousProduct.name,
    nextProductName: nextProduct.name,
  };
}

export type Automation = 'on' | 'off';

function getAutomationStatus(automation: SystemAutomation): 'off' | 'on' {
  switch (automation) {
    case SystemAutomation.ON:
      return 'on';
    case SystemAutomation.OFF:
      return 'off';
    case SystemAutomation.UNSPECIFIED:
      throw new Error('Detected invalid automation UNSPECIFIED');
    default:
      return assertUnreachable(automation);
  }
}

export class SystemModel {
  readonly id: string;

  readonly title: string;

  readonly name: string;

  readonly automation: Automation;

  readonly reservoirs: ReservoirModel[];

  readonly drains: DrainModel[];

  readonly pressurePipes: DrainModel[];

  readonly products: ProductModel[];

  readonly devices: DeviceModel[];

  readonly state: SystemState;

  readonly googlePlaceId?: string;

  readonly location: string;

  readonly crmNumber?: string;

  readonly serviceContractEndDate?: Date;

  readonly remarks?: string;

  readonly minTemperature: number;

  readonly growingMonths: number[];

  readonly topologyName: string;

  readonly silentHours?: SilentHours;

  private productByIdMap = new Map<string, ProductModel>();

  private reservoirByIdMap = new Map<string, ReservoirModel>();

  private drainByIdMap = new Map<string, DrainModel>();

  private pressurePipeByIdMap = new Map<string, DrainModel>();

  private productsByReservoirName = new Map<string, Product[]>();

  private productByDrainName = new Map<string, Product>();

  private deviceByPeripheral = new Map<Peripheral, DeviceModel>();

  private peripheralByName = new Map<string, Peripheral>();

  private issuesByDeviceName = new Map<string, IssueModel[]>();

  constructor(
    system: System,
    issuesResponse: ListIssuesResponse = new ListIssuesResponse(),
  ) {
    assertIsNonBlankString(system.name);
    assertIsNonBlankString(system.title);

    this.id = idFromName(system.name);
    this.title = system.title;
    this.name = system.name;
    this.googlePlaceId = system.googlePlaceId || undefined;
    this.location = system.location;
    this.crmNumber = system.crmNumber || undefined;
    this.remarks = system.remarks || undefined;
    this.minTemperature = system.minTemperature;
    this.growingMonths = system.growingMonths;
    this.serviceContractEndDate = system.serviceContractEndDate?.toDate();
    this.automation = getAutomationStatus(system.automation);
    this.state = productionStateToSystemState(system.productionState);
    this.silentHours = system.silentHours;

    const issuesModel = new IssuesModel(issuesResponse);
    issuesModel.issues.forEach((issue) => {
      if (!this.issuesByDeviceName.has(issue.resourceName)) {
        this.issuesByDeviceName.set(issue.resourceName, []);
      }
      this.issuesByDeviceName.get(issue.resourceName)?.push(issue);
    });

    this.initPeripheralMaps(system.devices);
    const topology = system.topologies.at(0);

    assertIsDefined(topology);

    this.topologyName = topology.name;

    this.initProductByReservoirName(topology);
    this.reservoirs = this.initReservoirs(topology);
    this.initProductByIdMap();
    this.initProductByDrainName(topology.products);
    this.drains = this.initDrains(topology.drains);
    this.pressurePipes = this.initPressurePipes(topology.drains);

    this.products = Array.from(this.productByIdMap.values());

    this.devices = system.devices.map((device) => new DeviceModel(device));

    this.initDrainByIdMap();
    this.initPressurePipeByIdMap();
  }

  private initProductByDrainName(products: Product[]) {
    products.forEach((product) => {
      const { position } = product;
      if (!position) {
        return;
      }
      this.productByDrainName.set(position.drainName, product);
    });
  }

  private initDrains(drains: Drain[]): DrainModel[] {
    return drains
      .filter((drain) => !drain.pressurePipe)
      .map((drain) => {
        const product = this.productByDrainName.get(drain.name);

        return new DrainModel(drain, product);
      });
  }

  private initPressurePipes(drains: Drain[]): DrainModel[] {
    return drains
      .filter((drain) => drain.pressurePipe)
      .map((drain) => {
        const product = this.productByDrainName.get(drain.name);

        return new DrainModel(drain, product);
      });
  }

  productById(productId: string) {
    return this.productByIdMap.get(productId);
  }

  drainById(drainId: string) {
    return this.drainByIdMap.get(drainId);
  }

  pressurePipeById(drainId: string) {
    return this.pressurePipeByIdMap.get(drainId);
  }

  reservoirById(reservoirId: string) {
    return this.reservoirByIdMap.get(reservoirId);
  }

  getReservoirNames() {
    return this.reservoirs.map((reservoir) => reservoir.name);
  }

  get hasUncommissionedDevices() {
    return this.devices.some(
      (device) =>
        device.commissioningState !== Device_CommissioningState.OPERATIONAL,
    );
  }

  get hasDevicesWithPendingCloudConnection() {
    return this.devices.some(
      (device) =>
        device.commissioningState ===
        Device_CommissioningState.PENDING_CLOUD_CONNECTION,
    );
  }

  private initPeripheralMaps(devices: Device[]) {
    devices.forEach((device) => {
      device.peripherals.forEach((peripheral) => {
        this.peripheralByName.set(peripheral.name, peripheral);
        const deviceModel = new DeviceModel(device);
        this.deviceByPeripheral.set(peripheral, deviceModel);
      });
    });
  }

  private initProductByReservoirName(topology: Topology) {
    const productResponses = topology.products ?? [];
    productResponses.forEach((product) => {
      const reservoirName = product.position?.reservoirName!;
      const target = this.productsByReservoirName.get(reservoirName) ?? [];
      target.push(product);
      this.productsByReservoirName.set(reservoirName, target);
    });
  }

  private getPeripheralsForProduct(product: Product): Peripheral[] {
    return product.peripheralResourceNames.map((resourceName) =>
      this.getPeripheralByName(resourceName),
    );
  }

  private getPeripheralByName(resourceName: string): Peripheral {
    const peripheral = this.peripheralByName.get(resourceName);
    assertIsDefined(peripheral);
    return peripheral;
  }

  private initReservoirs(topology: Topology): ReservoirModel[] {
    const products = Array.from(this.productsByReservoirName.values()).flat();

    return (topology?.reservoirs ?? [])
      .sort((a, b) => {
        if (a.title === b.title) {
          return a.name.localeCompare(b.name);
        }

        return a.title.localeCompare(b.title);
      })
      .map((reservoir) => {
        const productsForReservoir =
          this.productsByReservoirName.get(reservoir.name) ?? [];
        const deviceContext =
          productsForReservoir.length > 0 && products.length > 0
            ? getProductContext(
                products,
                productsForReservoir[0],
                productsForReservoir.at(-1)!,
              )
            : undefined;
        return new ReservoirModel(
          reservoir,
          productsForReservoir.map((product) =>
            this.createProductInfo(product),
          ),
          deviceContext,
        );
      });
  }

  private createProductInfo(product: Product): ProductInfo {
    const peripherals = this.getPeripheralsForProduct(product);
    const devicesWithDuplicates = peripherals.map((p) => {
      const device = this.deviceByPeripheral.get(p);
      return device;
    });
    // In practice a product will only have one device, but let's not make that assumption
    const devices = Array.from(new Set(devicesWithDuplicates));

    const device = devices[0] || DeviceModel.createEmpty();
    return {
      product,
      device,
      peripherals,
      issues: this.issuesByDeviceName.get(device.name) ?? [],
    };
  }

  private initDrainByIdMap() {
    this.drains.forEach((drain) => {
      this.drainByIdMap.set(drain.id, drain);
    });
  }

  private initPressurePipeByIdMap() {
    this.pressurePipes.forEach((drain) => {
      this.pressurePipeByIdMap.set(drain.id, drain);
    });
  }

  private initProductByIdMap() {
    this.reservoirs
      .flatMap((reservoir) => {
        this.reservoirByIdMap.set(reservoir.id, reservoir);
        return reservoir.products;
      })
      .forEach((product) => {
        this.productByIdMap.set(product.id, product);
      });
  }
}
