import { MetricType } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/metrics_pb';
import { ProductModel } from '~/shared/models/system/ProductModel';
import {
  ReservoirModel,
  SystemModel,
} from '~/shared/models/system/SystemModel';
import { assertIsNonBlankString } from '~/types/assert-type';

export const createRoutes = {
  Advanced: 'advanced',
  DynamicInfo: 'dynamic-info',
  Dashboard: '/dashboard/:systemId?',
  Downloads: '/downloads/:systemId?',
  Product: 'product/:productId',
  Reservoir: 'reservoir/:reservoirId',
  Drain: 'drain/:drainId',
  ForgotPassword: '/forgot-password',
  ForgotPasswordConfirm: '/forgot-password-confirm',
  CreatePassword: '/create-password',
  Device: 'device/:deviceId',
  Metrics: 'metrics',
  NotFound: '*',
  Profile: '/profile',
  Issues: '/issues',
  SignIn: '/signin',
  Support: '/support',
  System: '/system/:systemId?',
  SystemDesign: '/system/:systemId/design',
  SystemCreate: '/systems/create',
  SystemEdit: '/systems/:systemId/edit',
  Unauthorized: '/no-access',
  TermsConditions: '/accept-terms-and-conditions',
  Systems: '/systems',
  SystemUsers: '/users/:systemId?',
  SystemNotes: '/notes/:systemId?',
};

type RouteKeys = keyof typeof createRoutes;
export type RouteValues = (typeof createRoutes)[RouteKeys];

export const createDashboardRoute = (systemId?: SystemModel['id']) => {
  if (!systemId) {
    return createRoutes.Dashboard.replace('/:systemId?', '');
  }

  return createRoutes.Dashboard.replace(':systemId?', systemId);
};

export const createSystemRoute = (systemId?: SystemModel['id']) => {
  if (!systemId) {
    return createRoutes.System.replace('/:systemId?', '');
  }

  return createRoutes.System.replace(':systemId?', systemId);
};

export const createSystemDesignRoute = (systemId: SystemModel['id']) =>
  createRoutes.SystemDesign.replace(':systemId', systemId);

export const createSystemEditRoute = (systemId: SystemModel['id']) =>
  createRoutes.SystemEdit.replace(':systemId', systemId);

export const createRelativeProductRoute = (productId: ProductModel['id']) => {
  assertIsNonBlankString(productId);
  return createRoutes.Product.replace(':productId', productId);
};

export const createRelativeReservoirRoute = (
  reservoirId: ReservoirModel['id'],
) => {
  assertIsNonBlankString(reservoirId);
  return createRoutes.Reservoir.replace(':reservoirId', reservoirId);
};

export const createRelativeDrainRoute = (drainId: string) => {
  assertIsNonBlankString(drainId);
  return createRoutes.Drain.replace(':drainId', drainId);
};

export const createDrainDesignRoute = (systemId: string, drainId: string) => {
  assertIsNonBlankString(systemId);
  assertIsNonBlankString(drainId);
  return `${createSystemDesignRoute(systemId)}/${createRelativeDrainRoute(
    drainId,
  )}`;
};

export const createProductDesignRoute = (
  systemId: string,
  productId: string,
) => {
  assertIsNonBlankString(systemId);
  assertIsNonBlankString(productId);
  return `${createSystemDesignRoute(systemId)}/${createRelativeProductRoute(
    productId,
  )}`;
};

export const createRelativeDeviceRoute = (deviceId: string) => {
  assertIsNonBlankString(deviceId);
  return createRoutes.Device.replace(':deviceId', deviceId);
};

function createSystemProductRoute(systemId: string, productId: string) {
  assertIsNonBlankString(systemId);
  return `${createSystemRoute(systemId)}/${createRelativeProductRoute(
    productId,
  )}`;
}

export function createSystemReservoirRoute(
  systemId: string,
  reservoirId: string,
) {
  assertIsNonBlankString(systemId);
  return `${createSystemRoute(systemId)}/${createRelativeReservoirRoute(
    reservoirId,
  )}`;
}

export const createDynamicReservoirRoute = ({
  systemId,
  reservoirId,
}: {
  systemId: string;
  reservoirId: string;
}) =>
  `${createSystemReservoirRoute(systemId, reservoirId)}/${
    createRoutes.DynamicInfo
  }`;

export const createAdvancedRoute = ({
  systemId,
  productId,
}: {
  systemId: string;
  productId: string;
}) => `${createSystemProductRoute(systemId, productId)}/advanced`;

export const METRICS_DATA_PARAM_NAME = 'data';

export function createMetricsRoute({
  systemId,
  deviceId,
  productId,
  metricType,
}: {
  systemId: string;
  deviceId: string;
  productId: string;
  metricType: MetricType;
}) {
  assertIsNonBlankString(systemId);
  assertIsNonBlankString(deviceId);
  assertIsNonBlankString(productId);

  const data = [
    {
      productId,
      deviceId,
      metricType: MetricType[metricType],
    },
    {
      productId,
      deviceId,
    },
    {
      productId,
      deviceId,
    },
  ];

  const encoded = encodeURIComponent(JSON.stringify(data));

  return `${createSystemRoute(
    systemId,
  )}/metrics?${METRICS_DATA_PARAM_NAME}=${encoded}`;
}

export const createDownloadsRoute = (systemId?: SystemModel['id']) => {
  if (!systemId) {
    return createRoutes.Downloads.replace('/:systemId?', '');
  }

  return createRoutes.Downloads.replace(':systemId?', systemId);
};

export const createSystemUsersRoute = (systemId?: SystemModel['id']) => {
  if (!systemId) {
    return createRoutes.SystemUsers.replace('/:systemId?', '');
  }

  return createRoutes.SystemUsers.replace(':systemId?', systemId);
};

export const createSystemNotesRoute = (systemId?: SystemModel['id']) => {
  if (!systemId) {
    return createRoutes.SystemNotes.replace('/:systemId?', '');
  }

  return createRoutes.SystemNotes.replace(':systemId?', systemId);
};
