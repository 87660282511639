import { Timestamp } from '@bufbuild/protobuf';
import {
  Event,
  EventType,
} from '@wavingroup/aqora-v2-api/wavin/aqora/v2/event_pb';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import { assertIsDefined, assertUnreachable } from '~/types/assert-type';

export const noteTypes = [
  EventType.NOTE_ACTION,
  EventType.NOTE_OBSERVATION,
  EventType.NOTE_PROBLEM,
] as const;

export type NoteType = (typeof noteTypes)[number];

export class NoteModel {
  resourceName: string;

  title: string;

  type: NoteType;

  user: string;

  content: string;

  timestamp?: Timestamp;

  time?: string;

  constructor(event: Event, resourceTitle: string) {
    this.resourceName = event.resourceName;

    if (
      event.type !== EventType.NOTE_ACTION &&
      event.type !== EventType.NOTE_OBSERVATION &&
      event.type !== EventType.NOTE_PROBLEM
    ) {
      throw new Error('Incorrect event type');
    }

    this.type = event.type;

    this.title = resourceTitle;

    this.user = NoteModel.getUser(event);

    this.content = NoteModel.getNote(event);

    this.timestamp = event.lastOccuranceTime;

    this.time = dayjs(event.lastOccuranceTime?.toDate()).format(
      'YYYY-MM-DD HH:mm:ss',
    );
  }

  private static getUser(event: Event) {
    const user = event.payloadInfo.find(({ key }) => key === 'User ID')?.value;
    assertIsDefined(user);
    return user;
  }

  private static getNote(event: Event) {
    const note = event.payloadInfo.find(({ key }) => key === 'Note')?.value;
    assertIsDefined(note);
    return note;
  }

  static getTypeLabel(type: NoteType, t: TFunction): string {
    switch (type) {
      case EventType.NOTE_ACTION:
        return t('systemNotes.types.action');
      case EventType.NOTE_OBSERVATION:
        return t('systemNotes.types.observation');
      case EventType.NOTE_PROBLEM:
        return t('systemNotes.types.problem');
      default:
        return assertUnreachable(type);
    }
  }
}
