import { ListEventsResponse } from '@wavingroup/aqora-v2-api/wavin/aqora/v2/aqora_service_pb';
import { NoteModel } from '~/pages/SystemNotes/models/NoteModel';
import { SystemModel } from '~/shared/models/system/SystemModel';
import { assertIsDefined } from '~/types/assert-type';

type NoteResource = {
  title: string;
  resourceName: string;
};

export class NotesListModel {
  notes: NoteModel[];

  systemResources: NoteResource[];

  constructor(listEventsResponses: ListEventsResponse[], system: SystemModel) {
    this.systemResources = NotesListModel.getSystemResources(system);

    this.notes = listEventsResponses.flatMap((response) =>
      response.events.map((event) => {
        const resource = this.getResourceByResourceName(event.resourceName);
        assertIsDefined(resource);
        return new NoteModel(event, resource.title);
      }),
    );
  }

  static getSystemResources(system: SystemModel): NoteResource[] {
    const resources = [];

    resources.push({ title: system.title, resourceName: system.name });

    system.reservoirs.forEach((reservoir) => {
      resources.push({ title: reservoir.title, resourceName: reservoir.name });
    });

    system.products.forEach((product) => {
      resources.push({ title: product.title, resourceName: product.name });
    });

    return resources;
  }

  getResourceByResourceName(resourceName: string) {
    return this.systemResources.find(
      (resource) => resource.resourceName === resourceName,
    );
  }
}
